import React, { useEffect } from "react";
import { Container } from "@mui/material";

export default function PrivacyPolicy() {
  const [appName, setAppName] = React.useState("");
  useEffect(() => {
    function getAppName() {
      const app = window.location.pathname;
      console.log("app", app);
      if (app.includes("sara-church")) {
        setAppName("SARA CHURCH");
      } else if (app.includes("arena-conference")) {
        setAppName("ARENA CONFERENCE");
      }
    }

    getAppName();
  }, []);
  return (
    <Container>
      <div style={{ marginTop: 100 }}>
        <h2>TERMOS DE SERVIÇO</h2>
        <section>
          <h4>VISÃO GERAL</h4>
          <div>
            <p>
              Em todo o aplicativo, os termos “nós” e "nosso" se referem ao
              APLICATIVO {appName}. A PIPETECH proporciona esse aplicativo,
              incluindo todas as informações, ferramentas e serviços disponíveis
              neste aplicativo para você, o usuário, com a condição da sua
              aceitação de todos os termos, condições, políticas e avisos
              declarados aqui.
            </p>
            <p>
              Ao visitar nosso aplicativo e/ou comprar alguma coisa no nosso
              aplicativo, você está utilizando nossos “Serviços”.
              Consequentemente, você concorda com os seguintes termos e
              condições (“Termos de serviço”, “Termos”), incluindo os termos e
              condições e políticas adicionais mencionados neste documento e/ou
              disponíveis por hyperlink. Esses Termos de serviço se aplicam a
              todos os usuários do aplicativo, incluindo, sem limitação, os
              usuários que são navegadores, fornecedores, clientes, lojistas
              e/ou contribuidores de conteúdo.
            </p>
            <p>
              Por favor, leia estes Termos de serviço cuidadosamente antes de
              acessar ou utilizar o nosso aplicativo. Ao acessar ou usar
              qualquer parte do aplicativo, você concorda com os Termos de
              serviço. Se você não concorda com todos os termos e condições
              deste acordo, então você não pode acessar o aplicativo ou usar
              quaisquer serviços. Se esses Termos de serviço são considerados
              uma oferta, a aceitação é expressamente limitada a esses Termos de
              serviço.
            </p>
            <p>
              Quaisquer novos recursos ou ferramentas que forem adicionados à
              loja atual também devem estar sujeitos aos Termos de serviço. Você
              pode revisar a versão mais atual dos Termos de serviço quando
              quiser nesta página. Reservamos o direito de atualizar, alterar ou
              trocar qualquer parte destes Termos de serviço ao publicar
              atualizações e/ou alterações no nosso aplicativo. É sua
              responsabilidade verificar as alterações feitas nesta página
              periodicamente. Seu uso contínuo ou acesso ao aplicativo após a
              publicação de quaisquer alterações constitui aceitação de tais
              alterações.
            </p>
          </div>
        </section>

        <section>
          <h4>SEÇÃO 1 - TERMOS DA LOJA VIRTUAL</h4>
          <p>
            Ao concordar com os Termos de serviço, você confirma que você é
            maior de idade em seu estado ou província de residência e que você
            nos deu seu consentimento para permitir que qualquer um dos seus
            dependentes menores de idade usem esse aplicativo.
          </p>
          <p>
            Você não deve usar nossos produtos para qualquer fim ilegal ou não
            autorizado. Você também não pode, ao usufruir deste Serviço, violar
            quaisquer leis em sua jurisdição (incluindo, mas não limitado, a
            leis de direitos autorais).
          </p>
          <p>
            Você não deve transmitir nenhum vírus ou qualquer código de natureza
            destrutiva.
          </p>
          <p>
            Violar qualquer um dos Termos tem como consequência a rescisão
            imediata dos seus serviços.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 2 - CONDIÇÕES GERAIS</h4>
          <p>
            Reservamos o direito de recusar o serviço a qualquer pessoa por
            qualquer motivo a qualquer momento.
          </p>
          <p>
            Você entende que o seu conteúdo (não incluindo informações de cartão
            de crédito), pode ser transferido sem criptografia e pode: (a) ser
            transmitido por várias redes; e (b) sofrer alterações para se
            adaptar e se adequar às exigências técnicas de conexão de redes ou
            dispositivos. As informações de cartão de crédito sempre são
            criptografadas durante a transferência entre redes.
          </p>
          <p>
            Você concorda em não reproduzir, duplicar, copiar, vender, revender
            ou explorar qualquer parte do Serviço, uso do Serviço, acesso ao
            Serviço, ou qualquer contato no aplicativo através do qual o serviço
            é fornecido, sem nossa permissão expressa por escrito.
          </p>
          <p>
            Os títulos usados neste acordo são incluídos apenas por conveniência
            e não limitam ou afetam os Termos.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 3 - PRECISÃO, INTEGRIDADE E ATUALIZAÇÃO DAS INFORMAÇÕES</h4>
          <p>
            Não somos responsáveis por informações disponibilizadas neste
            aplicativo que não sejam precisas, completas ou atuais. O material
            desse aplicativo é fornecido apenas para fins informativos e não
            deve ser usado como a única base para tomar decisões sem consultar
            fontes de informações primárias, mais precisas, mais completas ou
            mais atuais. Qualquer utilização do material desse aplicativo é por
            sua conta e risco.
          </p>
          <p>
            Esse aplicativo pode conter certas informações históricas. As
            informações históricas podem não ser atuais e são fornecidas apenas
            para sua referência. Reservamos o direito de modificar o conteúdo
            desse aplicativo a qualquer momento, mas nós não temos obrigação de
            atualizar nenhuma informação em nosso aplicativo. Você concorda que
            é de sua responsabilidade monitorar alterações no nosso aplicativo.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 4 - MODIFICAÇÕES DO SERVIÇO E PREÇOS</h4>
          <p>
            Os preços dos nossos produtos estão sujeitos a alterações sem
            notificação.
          </p>
          <p>
            Reservamos o direito de, a qualquer momento, modificar ou
            descontinuar o Serviço (ou qualquer parte ou conteúdo do mesmo) sem
            notificação em qualquer momento.
          </p>
          <p>
            Não nos responsabilizamos por você ou por qualquer terceiro por
            qualquer modificação, alteração de preço, suspensão ou
            descontinuação do Serviço.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 5 - PRODUTOS OU SERVIÇOS (caso aplicável)</h4>
          <p>
            Certos produtos ou serviços podem estar disponíveis exclusivamente
            online através do aplicativo. Tais produtos ou serviços podem ter
            quantidades limitadas e são sujeitos a apenas devolução ou troca, de
            acordo com nossa Política de devolução.
          </p>
          <p>
            Fizemos todo o esforço possível da forma mais precisa das cores e
            imagens dos nossos produtos que aparecem na loja. Não podemos
            garantir que a exibição de qualquer cor no monitor do seu computador
            será precisa.
          </p>
          <p>
            Reservamos o direito, mas não somos obrigados a limitar as vendas de
            nossos produtos ou serviços para qualquer pessoa, região geográfica
            ou jurisdição. Podemos exercer esse direito conforme o caso.
            Reservamos o direito de limitar as quantidades de quaisquer produtos
            ou serviços que oferecemos. Todas as descrições de produtos ou
            preços de produtos são sujeitos a alteração a qualquer momento sem
            notificação, a nosso exclusivo critério. Reservamos o direito de
            descontinuar qualquer produto a qualquer momento. Qualquer oferta
            feita por qualquer produto ou serviço nesse aplicativo é nula onde
            for proibido por lei.
          </p>
          <p>
            Não garantimos que a qualidade de quaisquer produtos, serviços,
            informações ou outros materiais comprados ou obtidos por você vão
            atender às suas expectativas, ou que quaisquer erros no Serviço
            serão corrigidos.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 6 - PRECISÃO DE INFORMAÇÕES DE FATURAMENTO E CONTA</h4>
          <p>
            Reservamos o direito de recusar qualquer pedido que você nos fizer.
            Podemos, a nosso próprio critério, limitar ou cancelar o número de
            produtos por pessoa, por domicílio ou por pedido. Tais restrições
            podem incluir pedidos feitos na mesma conta de cliente, no mesmo
            cartão de crédito, e/ou pedidos que usam a mesma fatura e/ou
            endereço de envio. Caso façamos alterações ou cancelemos um pedido,
            pode ser que o notifiquem por e-mail e/ou endereço/número de
            telefone de faturamento fornecidos no momento que o pedido foi
            feito. Reservamos o direito de limitar ou proibir pedidos que, a
            nosso critério exclusivo, parecem ser feitos por comerciantes,
            revendedores ou distribuidores.
          </p>
          <p>
            Você concorda em fornecer suas informações de conta e compra
            completas para todas as compras feitas em nossa loja. Você concorda
            em atualizar prontamente sua conta e outras informações, incluindo
            seu e-mail, números de cartão de crédito e datas de validade, para
            que possamos completar suas transações e contatar você quando
            precisar.
          </p>
          <p>
            Para mais detalhes, por favor, revise nossa Política de devolução.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 7 - FERRAMENTAS OPCIONAIS</h4>
          <p>
            Podemos te dar acesso a ferramentas de terceiros que não monitoramos
            e nem temos qualquer controle.
          </p>
          <p>
            Você reconhece e concorda que nós fornecemos acesso a tais
            ferramentas ”como elas são” e “conforme a disponibilidade” sem
            quaisquer garantias, representações ou condições de qualquer tipo e
            sem qualquer endosso. Não nos responsabilizamos de forma alguma pelo
            seu uso de ferramentas opcionais de terceiros.
          </p>
          <p>
            Qualquer uso de ferramentas opcionais oferecidas através do
            aplicativo é inteiramente por sua conta e risco e você se
            familiariza e aprova os termos das ferramentas que são fornecidas
            por fornecedor(es) terceiro(s).
          </p>
          <p>
            Também podemos, futuramente, oferecer novos serviços e/ou recursos
            através do aplicativo (incluindo o lançamento de novas ferramentas e
            recursos). Tais recursos e/ou serviços novos também devem estar
            sujeitos a esses Termos de serviço.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 8 - LINKS DE TERCEIROS</h4>
          <p>
            Certos produtos, conteúdos e serviços disponíveis pelo nosso Serviço
            podem incluir materiais de terceiros.
          </p>
          <p>
            Os links de terceiros nesse aplicativo podem te direcionar para
            aplicativos de terceiros que não são afiliados a nós. Não nos
            responsabilizamos por examinar ou avaliar o conteúdo ou precisão.
            Não garantimos e nem temos obrigação ou responsabilidade por
            quaisquer materiais ou aplicativos de terceiros, ou por quaisquer
            outros materiais, produtos ou serviços de terceiros.
          </p>

          <p>
            Não somos responsáveis por quaisquer danos ou prejuízos relacionados
            com a compra ou uso de mercadorias, serviços, recursos, conteúdo, ou
            quaisquer outras transações feitas em conexão com quaisquer
            aplicativos de terceiros. Por favor, revise com cuidado as políticas
            e práticas de terceiros e certifique-se que você as entende antes de
            efetuar qualquer transação. As queixas, reclamações, preocupações ou
            questões relativas a produtos de terceiros devem ser direcionadas ao
            terceiro.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 9 - COMENTÁRIOS, FEEDBACK, ETC. DO USUÁRIO</h4>
          <p>
            Se, a nosso pedido, você enviar certos itens específicos (por
            exemplo, participação em um concurso), ou sem um pedido nosso, você
            enviar ideias criativas, sugestões, propostas, planos, ou outros
            materiais, seja online, por e-mail, pelo correio, ou de outra forma
            (em conjunto chamados de 'comentários'), você concorda que podemos,
            a qualquer momento, sem restrição, editar, copiar, publicar,
            distribuir, traduzir e de outra forma usar quaisquer comentários que
            você encaminhar para nós. Não nos responsabilizamos por:
          </p>
          <ul>
            <li>(1) manter quaisquer comentários em sigilo;</li>
            <li>(2) indenizar por quaisquer comentários; ou</li>
            <li>(3) responder quaisquer comentários.</li>
          </ul>
          <p>
            Podemos, mas não temos a obrigação, de monitorar, editar ou remover
            conteúdo que nós determinamos a nosso próprio critério ser contra a
            lei, ofensivo, ameaçador, calunioso, difamatório, pornográfico,
            obsceno ou censurável ou que viole a propriedade intelectual de
            terceiros ou estes Termos de serviço.
          </p>
          <p>
            Você concorda que seus comentários não violam qualquer direito de
            terceiros, incluindo direitos autorais, marcas registradas,
            privacidade, personalidade ou outro direito pessoal ou de
            propriedade. Você concorda que os seus comentários não vão conter
            material difamatório, ilegal, abusivo ou obsceno. Eles também não
            conterão nenhum vírus de computador ou outro malware que possa
            afetar a operação do Serviço ou qualquer aplicativo relacionado.
            Você não pode usar um endereço de e-mail falso, fingir ser alguém
            diferente de si mesmo, ou de outra forma enganar a nós ou terceiros
            quanto à origem de quaisquer comentários. Você é o único responsável
            por quaisquer comentários que você faz e pela veracidade deles. Nós
            não assumimos qualquer responsabilidade ou obrigação por quaisquer
            comentários publicados por você ou por qualquer terceiro.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 10 - INFORMAÇÕES PESSOAIS</h4>
          <p>
            O envio de suas informações pessoais através da loja é regido pela
            nossa Política de privacidade. Ver nossa Política de privacidade.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 11 - ERROS, IMPRECISÕES E OMISSÕES</h4>
          <p>
            Ocasionalmente, pode haver informações no nosso aplicativo ou no
            Serviço que contém erros tipográficos, imprecisões ou omissões que
            possam relacionar-se a descrições de produtos, preços, promoções,
            ofertas, taxas de envio do produto, o prazo de envio e
            disponibilidade. Reservamos o direito de corrigir quaisquer erros,
            imprecisões ou omissões, e de alterar ou atualizar informações ou
            cancelar encomendas caso qualquer informação no Serviço ou em
            qualquer aplicativo relacionado seja imprecisa, a qualquer momento e
            sem aviso prévio (até mesmo depois de você ter enviado o seu
            pedido).
          </p>
          <p>
            Não assumimos nenhuma obrigação de atualizar, alterar ou esclarecer
            informações no Serviço ou em qualquer aplicativo relacionado,
            incluindo, sem limitação, a informações sobre preços, exceto
            conforme exigido por lei. Nenhuma atualização específica ou data de
            atualização no Serviço ou em qualquer aplicativo relacionado, deve
            ser utilizada para indicar que todas as informações do Serviço ou em
            qualquer aplicativo relacionado tenham sido modificadas ou
            atualizadas.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 12 - USOS PROIBIDOS</h4>
          <p>
            Além de outras proibições, conforme estabelecido nos Termos de
            serviço, você está proibido de usar o aplicativo ou o conteúdo para:
            (a) fins ilícitos; (b) solicitar outras pessoas a realizar ou
            participar de quaisquer atos ilícitos; (c) violar quaisquer
            regulamentos internacionais, provinciais, estaduais ou federais,
            regras, leis ou regulamentos locais; (d) infringir ou violar nossos
            direitos de propriedade intelectual ou os direitos de propriedade
            intelectual de terceiros; (e) para assediar, abusar, insultar,
            danificar, difamar, caluniar, depreciar, intimidar ou discriminar
            com base em gênero, orientação sexual, religião, etnia, raça, idade,
            nacionalidade ou deficiência; (f) apresentar informações falsas ou
            enganosas; (g) fazer o envio ou transmitir vírus ou qualquer outro
            tipo de código malicioso que será ou poderá ser utilizado para
            afetar a funcionalidade ou operação do Serviço ou de qualquer
            aplicativo relacionado, outros aplicativos, ou da Internet; (h)
            coletar ou rastrear as informações pessoais de outras pessoas; (i)
            para enviar spam, phishing, pharm, pretext, spider, crawl, ou
            scrape; (j) para fins obscenos ou imorais; ou (k) para interferir ou
            contornar os recursos de segurança do Serviço ou de qualquer
            aplicativo relacionado, outros aplicativos, ou da Internet.
            Reservamos o direito de rescindir o seu uso do Serviço ou de
            qualquer aplicativo relacionado por violar qualquer um dos usos
            proibidos.
          </p>
        </section>

        <section>
          <h4>
            SEÇÃO 13 - ISENÇÃO DE RESPONSABILIDADE DE GARANTIAS; LIMITAÇÃO DE
            RESPONSABILIDADE
          </h4>
          <p>
            Nós não garantimos, representamos ou justificamos que o seu uso do
            nosso serviço será pontual, seguro, sem erros ou interrupções.
          </p>
          <p>
            Não garantimos que os resultados que possam ser obtidos pelo uso do
            serviço serão precisos ou confiáveis.
          </p>
          <p>
            Você concorda que de tempos em tempos, podemos remover o serviço por
            períodos indefinidos de tempo ou cancelar a qualquer momento, sem te
            notificar.
          </p>
          <p>
            Você concorda que o seu uso ou incapacidade de usar o serviço é por
            sua conta e risco. O serviço e todos os produtos e serviços
            entregues através do serviço são, exceto conforme declarado por nós)
            fornecidos sem garantia e conforme a disponibilidade para seu uso,
            sem quaisquer representações, garantias ou condições de qualquer
            tipo, expressas ou implícitas, incluindo todas as garantias
            implícitas ou condições de comercialização, quantidade, adequação a
            uma finalidade específica, durabilidade, título, e não violação.
          </p>

          <p>
            Em nenhuma circunstância o APLICATIVO {appName}, nossos diretores,
            oficiais, funcionários, afiliados, agentes, contratantes,
            estagiários, fornecedores, prestadores de serviços ou licenciadores
            serão responsáveis por qualquer prejuízo, perda, reclamação ou danos
            diretos, indiretos, incidentais, punitivos, especiais ou
            consequentes de qualquer tipo, incluindo, sem limitação, lucros
            cessantes, perda de receita, poupanças perdidas, perda de dados,
            custos de reposição, ou quaisquer danos semelhantes, seja com base
            em contrato, ato ilícito (incluindo negligência), responsabilidade
            objetiva ou de outra forma, decorrentes do seu uso de qualquer um
            dos serviços ou quaisquer produtos adquiridos usando o serviço, ou
            para qualquer outra reclamação relacionada de alguma forma ao seu
            uso do serviço ou qualquer produto, incluindo, mas não limitado a,
            quaisquer erros ou omissões em qualquer conteúdo, ou qualquer perda
            ou dano de qualquer tipo como resultado do uso do serviço ou
            qualquer conteúdo (ou produto) publicado, transmitido ou de outra
            forma disponível através do serviço, mesmo se alertado de tal
            possibilidade. Como alguns estados ou jurisdições não permitem a
            exclusão ou a limitação de responsabilidade por danos consequentes
            ou incidentes, em tais estados ou jurisdições, a nossa
            responsabilidade será limitada à extensão máxima permitida por lei.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 14 - INDENIZAÇÃO</h4>
          <p>
            Você concorda em indenizar, defender e isentar APLICATIVO {appName}{" "}
            e nossos subsidiários, afiliados, parceiros, funcionários,
            diretores, agentes, contratados, licenciantes, prestadores de
            serviços, subcontratados, fornecedores, estagiários e funcionários,
            de qualquer reclamação ou demanda, incluindo honorários de
            advogados, por quaisquer terceiros devido à violação destes Termos
            de serviço ou aos documentos que incorporam por referência, ou à
            violação de qualquer lei ou os direitos de um terceiro.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 15 - INDEPENDÊNCIA</h4>
          <p>
            No caso de qualquer disposição destes Termos de serviço ser
            considerada ilegal, nula ou ineficaz, tal disposição deve, contudo,
            ser aplicável até ao limite máximo permitido pela lei aplicável, e a
            porção inexequível será considerada separada desses Termos de
            serviço. Tal determinação não prejudica a validade e aplicabilidade
            de quaisquer outras disposições restantes.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 16 - RESCISÃO</h4>
          <p>
            As obrigações e responsabilidades das partes incorridas antes da
            data de rescisão devem continuar após a rescisão deste acordo para
            todos os efeitos.
          </p>
          <p>
            Estes Termos de Serviço estão em vigor, a menos que seja rescindido
            por você ou por nós. Você pode rescindir estes Termos de serviço a
            qualquer momento, notificando-nos que já não deseja utilizar os
            nossos serviços, ou quando você deixar de usar o nosso aplicativo.
          </p>
          <p>
            Se em nosso critério exclusivo você não cumprir com qualquer termo
            ou disposição destes Termos de serviço, nós também podemos rescindir
            este contrato a qualquer momento sem aviso prévio e você ficará
            responsável por todas as quantias devidas até a data da rescisão;
            também podemos lhe negar acesso a nossos Serviços (ou qualquer parte
            deles).
          </p>
        </section>

        <section>
          <h4>SEÇÃO 17 - ACORDO INTEGRAL</h4>
          <p>
            Caso não exerçamos ou executamos qualquer direito ou disposição
            destes Termos de serviço, isso não constituirá uma renúncia de tal
            direito ou disposição.
          </p>
          <p>
            Estes Termos de serviço e quaisquer políticas ou normas operacionais
            postadas por nós neste aplicativo ou no que diz respeito ao serviço
            constituem a totalidade do acordo entre nós. Estes termos regem o
            seu uso do Serviço, substituindo quaisquer acordos anteriores ou
            contemporâneos, comunicações e propostas, sejam verbais ou escritos,
            entre você e nós (incluindo, mas não limitado a quaisquer versões
            anteriores dos Termos de serviço).
          </p>
          <p>
            Quaisquer ambiguidades na interpretação destes Termos de serviço não
            devem ser interpretadas contra a parte que os redigiu.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 18 - LEGISLAÇÃO APLICÁVEL</h4>
          <p>
            Esses Termos de serviço e quaisquer acordos separados em que nós lhe
            fornecemos os Serviços devem ser regidos e interpretados de acordo
            com as leis de Brasília-DF.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 19 - ALTERAÇÕES DOS TERMOS DE SERVIÇO</h4>

          <p>
            Você pode rever a versão mais atual dos Termos de serviço a qualquer
            momento nessa página.
          </p>

          <p>
            Reservamos o direito, a nosso critério, de atualizar, modificar ou
            substituir qualquer parte destes Termos de serviço ao publicar
            atualizações e alterações no nosso aplicativo. É sua
            responsabilidade verificar nosso aplicativo periodicamente. Seu uso
            contínuo ou acesso ao nosso aplicativo ou ao Serviço após a
            publicação de quaisquer alterações a estes Termos de serviço
            constitui aceitação dessas alterações.
          </p>
        </section>

        <section>
          <h4>SEÇÃO 20 - INFORMAÇÕES DE CONTATO</h4>
          <p>
            As perguntas sobre os Termos de serviço devem ser enviadas para nós
            através do email contato@pipetech.com.br
          </p>
        </section>
      </div>
    </Container>
  );
}
